import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

// @mui-materials
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
// project imports
import Form from './form';
import OTP from './otp';
import CreateAccountCard from './createAccountCard';
import { packages as packageRoute } from 'src/constants/api';
import { unProtectedFetch as axios } from 'src/utils/axios';
import * as links from 'src/constants/externalPagesLinks';
// icosn
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  px: 3,
  py: 2,
  borderRadius: 1,
};

const SignUp = () => {
  const theme = useTheme();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(null);
  const [emailToVerify, setEmailToVerify] = useState(null);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const location = useLocation();

  const { data: packages, isLoading: isPackagesLoading } = useQuery({
    queryKey: ['packages'],
    queryFn: async () => {
      const response = await axios.get(packageRoute.get);
      return response.data;
    },
  });

  useEffect(() => {
    if (!location.state && packages) {
      setSelectedPackage(packages[0]._id);
      return;
    }
    if (location.state) {
      setSelectedPackage(location.state.package);
    }
  }, [location.state, packages]);

  const activePackages = useMemo(() => {
    if (!packages) return null;
    return packages?.filter((item) => item.status === 'active');
  }, [packages]);

  const handleClose = () => setVerifyModalOpen(false);
  const handleOpen = () => setVerifyModalOpen(true);

  const handleVerify = (email) => {
    setEmailToVerify(email);
    handleOpen();
  };

  return (
    <Fragment>
      <Stack alignItems='center' justifyContent='center' minHeight='100vh' gap={4}>
        <Stack
          sx={{
            minWidth: '400px',
            maxWidth: '400px',
            gap: 2,
            fontFamily: "'Roboto', sans-serif",
            '& *': {
              fontFamily: "'Roboto', sans-serif",
            },
          }}
        >
          <Card
            sx={{
              overflow: 'visible',
              position: 'relative',
              zIndex: 2,
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '4px',
                background: 'linear-gradient(225deg, hsla(228, 58%, 55%, 1) 0%, hsla(248, 49%, 54%, 1) 64%, hsla(209, 77%, 53%, 1) 100%)',
              },
            }}
          >
            <CardHeader
              title={
                <a href={links.Home} target='_blank' rel='noreferrer'>
                  <Box
                    sx={{
                      width: '180px',
                      mx: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',

                      '& img': {
                        objectFit: 'contain',
                        maxWidth: '100%',
                      },
                    }}
                  >
                    <img
                      className='header__logo-image'
                      src='https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=520&amp;height=81&amp;name=GA_universe-1-1-3.webp'
                      loading='eager'
                      alt='Your path towards digitalization will be with GA_Universe'
                      height='81'
                      width='520'
                      srcSet='https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=260&amp;height=41&amp;name=GA_universe-1-1-3.webp 260w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=520&amp;height=81&amp;name=GA_universe-1-1-3.webp 520w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=780&amp;height=122&amp;name=GA_universe-1-1-3.webp 780w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1040&amp;height=162&amp;name=GA_universe-1-1-3.webp 1040w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1300&amp;height=203&amp;name=GA_universe-1-1-3.webp 1300w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1560&amp;height=243&amp;name=GA_universe-1-1-3.webp 1560w'
                      sizes='(max-width: 520px) 100vw, 520px'
                    />
                  </Box>
                </a>
              }
            />
            <Typography variant='h6' component='h2' textAlign='center' letterSpacing={'-0.5px'} fontWeight={500} mt={2} color='#364252'>
              Create your account
            </Typography>
            <CardContent>
              <Form
                handleVerify={handleVerify}
                packages={activePackages}
                isPackagesLoading={isPackagesLoading}
                selectedPackage={selectedPackage}
                isEmailVerified={isEmailVerified}
                setIsEmailVerified={setIsEmailVerified}
                verifiedEmail={verifiedEmail}
                isPackageDefaultSelect={!packages}
              />
            </CardContent>
          </Card>
          <Card sx={{ position: 'relative' }}>
            <CardContent sx={{ pb: '8px !important' }}>
              <CreateAccountCard />
            </CardContent>
          </Card>
        </Stack>
      </Stack>

      <Modal open={verifyModalOpen} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={{ ...style, outline: 'none' }}>
          <Stack position='relative'>
            <Box position='absolute' right='-20px' top='-10px'>
              <IconButton
                onClick={handleClose}
                sx={{
                  border: `1px solid transparent !important`,
                  '&:hover': {
                    color: `${theme.palette.grey[600]} !important`,
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <OTP
              setIsEmailVerified={setIsEmailVerified}
              email={emailToVerify}
              setVerifiedEmail={setVerifiedEmail}
              handleCloseVerification={handleClose}
            />
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default SignUp;
