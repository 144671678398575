import React, { Fragment, useState } from 'react';
import { NavLink as RouteLink } from 'react-router-dom';
import './header.css';
// mui-material
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// project components
import Dropdown from './components/dropdown';
import GlobeSrc from '../assets/globe.webp';
import CollapseComponent from './components/collapsible';

// assets
import * as links from 'src/constants/externalPagesLinks';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main + ' !important',
  },
  '&.active': {
    color: theme.palette.primary.main + ' !important',
  },
}));

const closeIcon = (
  <svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' width='24' aria-hidden='true'>
    <g id='bars1_layer'>
      <path d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
    </g>
  </svg>
);
const hamburgerIcon = (
  <svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' width='24' aria-hidden='true'>
    <g id='bars1_layer'>
      <path d='M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z'></path>
    </g>
  </svg>
);

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  const handleToggle = () => setNavOpen((prev) => !prev);
  const matches = useMediaQuery('(min-width:900px)');

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  const {
    AboutUs,
    Contact,
    // Enterprise,
    Home,
    Product,
    Resources,
  } = links;

  const languageSelector = (
    <Dropdown
      header={
        <Stack direction='row' gap={1}>
          <span>
            <img src={GlobeSrc} alt='globe' />
          </span>
          <span style={{ fontWeight: 400 }}>English</span>
        </Stack>
      }
    >
      <ul>
        <li>English</li>
        <li>العربية</li>
      </ul>
    </Dropdown>
  );
  const productSelector = (
    <Dropdown header='Product'>
      <ul>
        <li>
          <a href={Product.ourPlatform}>Our Platform</a>
        </li>
        <li>
          <a href={Product.buildingInGA}>Building in GA</a>
        </li>
        <li>
          <a href={Product.dataIntegeration}>Data Integration</a>
        </li>
        <li>
          <a href={Product.reportingInGA}>Reporting In GA</a>
        </li>
      </ul>
    </Dropdown>
  );
  const resourcesSelector = (
    <Dropdown header='Resources'>
      <ul>
        <li>
          <a href={Resources.quickStartGuide}>Quick Start Guide</a>
        </li>
        <li>
          <a href={Resources.blogs}>Blogs</a>
        </li>
        <li>
          <a href={Resources.events}>Events</a>
        </li>
        <li>
          <a href={Resources.digitalTransformation}>Digital Transformation</a>
        </li>
        <li>
          <a href={Resources.docs}>Docs</a>
        </li>
      </ul>
    </Dropdown>
  );
  const aboutUs = (
    <Dropdown header='About us'>
      <ul>
        <li>
          <a href={AboutUs.ourVision}>Our Vision</a>
        </li>
        <li>
          <a href={AboutUs.ourMission}>Our Mission</a>
        </li>
        <li>
          <a href={AboutUs.ourHistory}>Our History</a>
        </li>
        <li>
          <a href={AboutUs.partner}>Partners</a>
        </li>
      </ul>
    </Dropdown>
  );
  const maxWidth = '1440px';

  // Desktop View
  if (matches) {
    return (
      <Fragment>
        <Toolbar
          sx={{
            bgcolor: 'background.paper',
            p: '0.7rem 0 !important',
            minHeight: '49.766px !important',
          }}
        >
          <Container sx={{ px: '50px !important', maxWidth: `${maxWidth} !important` }}>
            <Stack alignItems='flex-end'>{languageSelector}</Stack>
          </Container>
        </Toolbar>
        <AppBar
          position={trigger ? 'fixed' : 'static'}
          sx={{
            bgcolor: trigger ? 'background.paper' : 'transparent',
            boxShadow: trigger ? '0 0 10px 0 #00000029' : 'none',
          }}
          elevation={0}
        >
          <Toolbar
            sx={{
              p: trigger ? '15px 0' : '2.1rem 0',
              transition: 'all 200ms ease-in',
            }}
          >
            <Container sx={{ maxWidth: `${maxWidth} !important` }}>
              <Stack direction='row' justifyContent='space-between'>
                <Box
                  sx={{
                    width: '260px',
                    '& img': {
                      height: 'auto',
                      maxWidth: '100%',
                    },
                  }}
                >
                  <img
                    className='header__logo-image'
                    src='https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=520&amp;height=81&amp;name=GA_universe-1-1-3.webp'
                    loading='eager'
                    alt='Your path towards digitalization will be with GA_Universe'
                    height='81'
                    width='520'
                    srcSet='https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=260&amp;height=41&amp;name=GA_universe-1-1-3.webp 260w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=520&amp;height=81&amp;name=GA_universe-1-1-3.webp 520w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=780&amp;height=122&amp;name=GA_universe-1-1-3.webp 780w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1040&amp;height=162&amp;name=GA_universe-1-1-3.webp 1040w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1300&amp;height=203&amp;name=GA_universe-1-1-3.webp 1300w, https://ga-universe.com/hs-fs/hubfs/GA_universe-1-1-3.webp?width=1560&amp;height=243&amp;name=GA_universe-1-1-3.webp 1560w'
                    sizes='(max-width: 520px) 100vw, 520px'
                  />
                </Box>
                <Stack direction='row' spacing={'30px'} alignItems='center'>
                  <ul className='flex align-center justify-end flex-wrap'>
                    <StyledLink underline='none' className='whitespace-nowrap' href={Home}>
                      Home
                    </StyledLink>
                    {/* <StyledLink
											underline='none'
											className='whitespace-nowrap'
											href={Enterprise}
										>
											Enterprise
										</StyledLink> */}
                    <li>{productSelector}</li>
                    <li>{resourcesSelector}</li>
                    <li>
                      <StyledLink component={RouteLink} to='/' className={(isActive) => (isActive ? 'active' : '')}>
                        Pricing
                      </StyledLink>
                    </li>
                    <li>{aboutUs}</li>
                    <StyledLink underline='none' className='whitespace-nowrap' href={Contact}>
                      Contact Us
                    </StyledLink>
                  </ul>
                </Stack>
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
      </Fragment>
    );
  }
  const productSelectorMobile = (
    <CollapseComponent header='Product'>
      <ul className='collapse-navlist'>
        <li>
          <a href={Product.ourPlatform}>Our Platform</a>
        </li>
        <li>
          <a href={Product.buildingInGA}>Building in GA</a>
        </li>
        <li>
          <a href={Product.dataIntegeration}>Data Integration</a>
        </li>
        <li>
          <a href={Product.reportingInGA}>Reporting In GA</a>
        </li>
      </ul>
    </CollapseComponent>
  );
  const resourcesSelectorMobile = (
    <CollapseComponent header='Resources'>
      <ul>
        <li>
          <a href={Resources.quickStartGuide}>Quick Start Guide</a>
        </li>
        <li>
          <a href={Resources.blogs}>Blogs</a>
        </li>
        <li>
          <a href={Resources.events}>Events</a>
        </li>
        <li>
          <a href={Resources.digitalTransformation}>Digital Transformation</a>
        </li>
        <li>
          <a href={Resources.docs}>Docs</a>
        </li>
      </ul>
    </CollapseComponent>
  );
  const aboutUsSelectorMobile = (
    <CollapseComponent header='About Us'>
      <ul>
        <li>
          <a href={AboutUs.ourVision}>Our Vision</a>
        </li>
        <li>
          <a href={AboutUs.ourMission}>Our Mission</a>
        </li>
        <li>
          <a href={AboutUs.ourHistory}>Our History</a>
        </li>
        <li>
          <a href={AboutUs.partner}>Partners</a>
        </li>
      </ul>
    </CollapseComponent>
  );
  const languageSelectorMobile = (
    <CollapseComponent
      header={
        <Stack direction='row' gap={1}>
          <span>
            <img src={GlobeSrc} alt='globe' />
          </span>
          <span>English</span>
        </Stack>
      }
    >
      <ul>
        <li>English</li>
        <li>العربية</li>
      </ul>
    </CollapseComponent>
  );
  return (
    <Stack bgcolor='backgound.paper'>
      <Stack direction='row' alignItems='center' justifyContent='space-between' height={70} px={4}>
        <Box
          sx={{
            '& img': {
              height: 'auto',
              maxWidth: '100%',
            },
          }}
        >
          <img
            className='header__logo-image'
            src={`${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=260&amp;height=41&amp;name=GA_universe-1-1.png`}
            loading='eager'
            alt='GA_Universe'
            height='41'
            width='260'
            srcSet={`${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=130&amp;height=21&amp;name=GA_universe-1-1.png 130w, "${links.baseURL}"/hs-fs/hubfs/GA_universe-1-1.png?width=260&amp;height=41&amp;name=GA_universe-1-1.png 260w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=390&amp;height=62&amp;name=GA_universe-1-1.png 390w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=520&amp;height=82&amp;name=GA_universe-1-1.png 520w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=650&amp;height=103&amp;name=GA_universe-1-1.png 650w, ${links.baseURL}/hs-fs/hubfs/GA_universe-1-1.png?width=780&amp;height=123&amp;name=GA_universe-1-1.png 780w`}
            sizes='(max-width: 260px) 100vw, 260px'
          />
        </Box>
        <IconButton
          onClick={handleToggle}
          sx={{
            '& svg': { fill: 'black !important' },
            '&:focus': { border: 'none !important' },
          }}
        >
          {/* {navOpen ? <IconX size={24} /> : <IconMenu2 size={24} />} */}
          {navOpen ? closeIcon : hamburgerIcon}
        </IconButton>
      </Stack>
      {navOpen && (
        <AppBar position='static' sx={{ bgcolor: 'transparent' }} elevation={0}>
          <Toolbar
            sx={{
              minHeight: 'calc(100vh - 70px) !important',
              bgcolor: 'background.paper',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ul className='w-full mobile-navlist'>
              <li className='nav-item'>
                <StyledLink underline='none' href={Home}>
                  Home
                </StyledLink>
              </li>
              {/* <li className='nav-item'>
								<StyledLink underline='none' href={Enterprise}>
									Enterprise
								</StyledLink>
							</li> */}
              {/* <li className='nav-item'>{productSelector}</li> */}
              <li className='nav-item'>{productSelectorMobile}</li>
              <li className='nav-item'>{resourcesSelectorMobile}</li>
              <li className='nav-item'>{aboutUsSelectorMobile}</li>
              <li className='nav-item'>
                <StyledLink underline='none' href={Contact}>
                  Contact Us
                </StyledLink>
              </li>
              <li className='nav-item'>{languageSelectorMobile}</li>
            </ul>
          </Toolbar>
        </AppBar>
      )}
    </Stack>
  );
};

export default Header;
